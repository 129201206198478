/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useResponsive } from 'utils';
import config from 'config';

import { Button, Card, Typography } from '../alf-design';
import ClaimDetailsHeader from './ClaimDetailsHeader';
import WithoutClaims from './WithoutClaims';
import ClaimDetails from './ClaimDetails';
import './Claims.css';

const Claims = ({ claims }) => {
  const [expanded, setExpanded] = useState(0);
  const { screenIsDown } = useResponsive();
  const handleChange = (panel) => (newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const sortClaims = (a, b) => {
    if (a.claimStatus === 'Open' && b.claimStatus !== 'Open') {
      return -1;
    }
    if (a.claimStatus !== 'Open' && b.claimStatus === 'Open') {
      return 1;
    }

    if (a.claimStatus === 'Closed' && b.claimStatus === 'Closed') {
      const aClaimFiledDate = moment(a.claimFiledDate);
      const bClaimFiledDate = moment(b.claimFiledDate);

      if (aClaimFiledDate.isAfter(bClaimFiledDate)) {
        return -1;
      }
      if (aClaimFiledDate.isBefore(bClaimFiledDate)) {
        return 1;
      }
    }
    return 0;
  };

  return (
    <>
      <div className="alf-claims-root">
        {claims?.length === 0 || !claims ? (
          <WithoutClaims />
        ) : (
          <Card>
            <div className="alf-claims-title">
              <Typography type="heading3" component="h3">
                Claim Tracker
              </Typography>

              <div className="alf-claims-title-button">
                <Button
                  id="Report-Loss-button"
                  type="primary"
                  onClick={() => window.open(config.fnol)}
                  className="report-loss-button"
                  link
                >
                  File a Claim
                </Button>
              </div>
            </div>
            {screenIsDown('lg') ? (
              <>
                {claims.map((claim, index) => (
                  <ClaimDetails
                    claim={claim}
                    index={index}
                    handleChange={handleChange}
                    expanded={expanded}
                  />
                ))}
              </>
            ) : (
              <>
                {claims.sort(sortClaims).map((claim, index) => (
                  <div
                    key={claim.claimNumber}
                    header={<ClaimDetailsHeader claim={claim} />}
                  >
                    <ClaimDetails
                      claim={claim}
                      index={index}
                      handleChange={handleChange}
                      expanded={expanded}
                    />
                  </div>
                ))}
              </>
            )}
          </Card>
        )}
      </div>
    </>
  );
};

Claims.propTypes = {
  claims: PropTypes.arrayOf(PropTypes.shape({})),
};
export default withRouter(Claims);
