import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import './Status.css';

const TYPES = ['Open', 'Closed'];

const Status = ({ id, style, className, status }) => {
  return (
    <div
      id={id}
      data-testid={id}
      style={style}
      className={clsx('alf-status', className, status)}
    >
      {status}
    </div>
  );
};

Status.propTypes = {
  id: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string,
  status: PropTypes.oneOf(TYPES)
};

Status.defaultProps = {
  status: 'Open'
};

export default Status;
