import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import {
  withApi,
  formats,
  useToggle,
  usePolicy,
  useAuth0,
  usePayment,
  transform
} from 'utils';
import LinkPolicy from 'components/UserProfile/LinkPolicy';
import config from 'config';

import {
  Typography,
  Button,
  Row,
  Col,
  SelectPolicy,
  Spinner,
  showErrorAlert
} from '../alf-design';
import './Dashboard.css';
import { Claims } from '../claims';
import { PolicyDetails, AgencyDetails } from './sections';
import OverdueNotification from './OverdueNotification';
import PaymentHistory from './PaymentHistory';
import Billing from './sections/Billing';
import NoPolicies from './NoPolicies';
import PolicyDownloads from './PolicyDownloads';
import MakePayment from './MakePayment';
import LoadingMessage from './LoadingMessage';
import AdminPage from './AdminPage';

const styles = {
  circularProgress: {
    display: 'flex',
    justifyContent: 'center'
  }
};

const Dashboard = ({ history, location, api }) => {
  const [queryParams, setQueryParams] = useState(null);
  const [showNotification, toggleNotification] = useToggle(false);
  const [showHistory, toggleHistory] = useToggle(false);
  const [showPayment, togglePayment] = useState(false);
  const [showLinkPolicy, toggleLinkPolicy] = useState(false);
  const [showLoading, toggleLoading] = useState(false);
  const [showDownloads, toggleDownloads] = useToggle(false);
  const [billingLoading, setBillingLoading] = useState(false);
  const { user } = useAuth0();
  const isAdmin = user
    ? user.email.includes('@slideinsurance.com') &&
      user.role &&
      user.role?.isAdmin
    : false;
  const [searchView, setSearchView] = useState(false);
  const {
    policies,
    claims,
    selectedPolicy,
    loading,
    error,
    setError
  } = usePolicy(queryParams ? queryParams.policy : null, isAdmin, {
    loadPolicies: true
  });
  const [billingPolicy, setBillingPolicy] = useState(selectedPolicy);

  useEffect(() => {
    if (isAdmin) {
      setSearchView(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (error) {
      showErrorAlert({
        message: `${error.entity.charAt(0).toUpperCase() +
          error.entity.slice(1)} Error`,
        description: errorDescription(error.err, error.entity)
      });

      if (error.err.response && error.err.response.status === 404) {
        history.replace('/');
      }
    }
    if (error && error.entity === 'policy' && isAdmin) {
      setSearchView(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, history]);

  const handleChangePolicy = useCallback(
    (policyNumber) => {
      const route = `/?${queryString.stringify({
        ...queryParams,
        policy: policyNumber
      })}`;

      if (queryParams.policy) {
        history.push(route);
      } else {
        history.replace(route);
      }
    },
    [history, queryParams]
  );

  const errorDescription = (err, request) => {
    let description;
    if (err.response) {
      description = err.response.data.error;
    } else if (err.request) {
      description =
        'Network error. Please check your connection and try again.';
    } else {
      description = `Error attempting to fetch your ${request}. Refresh page to try again.`;
    }
    return description;
  };

  useEffect(() => {
    setBillingPolicy(selectedPolicy);
  }, [selectedPolicy]);

  useEffect(() => {
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute(
        'content',
        'Policy access is designed with you in mind. View your policy details, make payments, access your agent info, and more!'
      );
    }
  }, []);

  useEffect(() => {
    setQueryParams(queryString.parse(location.search));
    document.title = 'Online Policy Access for Slide Insurance Policyholders';
  }, [location.search]);

  useEffect(() => {
    if (queryParams && !queryParams.policy && policies && policies.length > 0) {
      handleChangePolicy(policies[0].policyNumber);
    }
  }, [queryParams, policies, history, handleChangePolicy, selectedPolicy]);

  useEffect(() => {
    if (queryParams && queryParams.errorMessage) {
      showErrorAlert({
        message: 'Manage Payment Error',
        description: queryParams.errorMessage
      });
      history.replace(
        `/?${queryString.stringify({
          ...queryParams,
          errorMessage: undefined
        })}`
      );
    }
  }, [queryParams, history]);

  const handleNewPolicy = () => {
    history.push(`/add-policy`);
  };

  const submitPayment = (body) => {
    const policyNumber = selectedPolicy.policyNumber;
    setBillingLoading(true);
    api.withAuth
      .post(`/policies/${policyNumber}/payments`, body)
      .then((res) => {
        if (res.status === 200) {
          getPolicy(policyNumber);
        }
      })
      .catch((err) => {
        setBillingPolicy(selectedPolicy);
        setBillingLoading(false);
        let message;
        if (err.response) {
          message = err.response.data.message;
        } else if (err.request) {
          message =
            'Network error. Please check your connection and try again.';
        } else {
          message = 'Something went wrong submitting your payment.';
        }
        showErrorAlert({
          message: err.response.status,
          description: message
        });
      });
  };

  const getPolicy = (policyNumber) => {
    setBillingLoading(true);
    api.withAuth
      .getPolicy(policyNumber)
      .then((res) => {
        setBillingPolicy(transform.policy(res.data));
        setBillingLoading(false);
      })
      .catch((err) => {
        setError(err);
      });
  };

  const { handleOneIncPayment } = usePayment({
    submitPayment,
    getPolicy,
    policyNumber: selectedPolicy?.policyNumber
  });

  const handlePayment = () => {
    togglePayment(false);
    toggleLoading(true);
    handleOneIncPayment(selectedPolicy, () => toggleLoading(false));
  };

  if (!loading && selectedPolicy === null && searchView) {
    return <AdminPage setSearchView={setSearchView} isSearchView />;
  }

  if (!loading && selectedPolicy === null) {
    return <NoPolicies error={error} handleNewPolicy={handleNewPolicy} />;
  }

  return !loading && selectedPolicy !== null ? (
    <div className="policy-view" style={{ marginTop: 0 }}>
      {isAdmin && (
        <div
          className="searchView"
          style={{ top: config.banner.display === 'true' ? 100 : 40 }}
        >
          <AdminPage setSearchView={setSearchView} isSearchView={false} />
        </div>
      )}
      <Typography type="heading1" component="h1" hidden>
        Dashboard
      </Typography>
      {policies && policies.length > 1 && (
        <SelectPolicy
          id="dashboard-select-policy"
          policies={policies}
          selectedPolicy={selectedPolicy}
          onSelect={(policy) => handleChangePolicy(policy.policyNumber)}
        />
      )}
      <div className="header">
        <div>
          <Typography
            id="dashboard-heading-policy"
            type="heading2"
            component="h2"
          >
            {formats.address(selectedPolicy.property)}
          </Typography>
        </div>
        <Button
          id="dashboard-button-add-policy"
          type="primary"
          link
          onClick={() => toggleLinkPolicy(true)}
          className="add-policy"
          disabled={isAdmin}
        >
          Add Existing Policy
        </Button>
      </div>

      <Row className="box-content" style={{ marginTop: 24 }} gutter={16}>
        <Col className="box-content" lg={14} style={{ flexDirection: 'row' }}>
          <PolicyDetails
            policy={selectedPolicy}
            toggleDownloads={toggleDownloads}
          />
        </Col>
        <Col
          lg={10}
          className="box-content"
          style={{ flexDirection: 'column' }}
        >
          <Billing
            policy={billingPolicy}
            handleHistoryClick={() => toggleHistory(true)}
            handleMakePaymentClick={() => togglePayment(true)}
            loading={billingLoading}
            getPolicy={getPolicy}
          />

          <AgencyDetails policy={selectedPolicy} />
        </Col>
      </Row>
      {/* TODO: HOLD OFF until the final mockup from Marketing
      claims && ( */}
      <Claims claims={claims} policyNumber={selectedPolicy.policyNumber} />
      {/* )} */}

      <OverdueNotification
        show={showNotification}
        price={selectedPolicy.price}
        onClose={toggleNotification}
        onPayment={() => {
          toggleNotification();
        }}
      />
      <PaymentHistory
        show={showHistory}
        onClose={() => toggleHistory(false)}
        policyNumber={selectedPolicy.policyNumber}
      />
      <PolicyDownloads
        show={showDownloads}
        onClose={() => toggleDownloads(false)}
        policyNumber={selectedPolicy.policyNumber}
      />
      {selectedPolicy.billing && (
        <MakePayment
          show={showPayment}
          onClose={() => togglePayment(false)}
          onPayment={handlePayment}
          selectedPolicy={billingPolicy}
        />
      )}
      <LinkPolicy
        show={showLinkPolicy}
        onClose={() => toggleLinkPolicy(false)}
        api={api}
        history={history}
        fromDashboard={showLinkPolicy}
      />
      <LoadingMessage show={showLoading} onClose={() => toggleLoading(false)} />
    </div>
  ) : (
    <div style={styles.circularProgress}>
      {loading && <Spinner id="dashboard-spinner" size={80} />}
    </div>
  );
};

Dashboard.propTypes = {
  history: PropTypes.object
};
export default withRouter(withApi(Dashboard));
