import React, { createRef, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Transition } from 'react-transition-group';

import Icon from '../Icon';
import Button from '../Button';
import './ExpansionPanel.css';

const duration = 600;

const defaultStyles = {
  height: 0,
  overflow: 'hidden',
  transition: `height ${duration}ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,
};

const ExpansionPanel = ({
  className,
  header,
  children,
  onChange,
  expanded: expandedProp,
  headerStyle,
  id,
  claim,
}) => {
  const controlled = !!onChange;

  const [expandedState, setExpanded] = useState(false);
  const [styles, setStyles] = useState(defaultStyles);
  const [divFocus, setDivFocus] = useState(false);
  const [buttonFocus, setButtonFocus] = useState(false);
  const divRef = createRef();

  const expanded = controlled ? expandedProp : expandedState;

  const handleChange = (e) =>
    controlled ? onChange(e, !expanded) : setExpanded(!expanded);

  const handleEnter = () => setStyles({ height: 0 });

  const handleExit = () =>
    setStyles({
      height: 'auto',
      overflow: 'visible',
    });
  const handleExiting = () => setStyles({});
  const handleEntering = () => setStyles({ height: 'auto' });
  const handleKeyUp = (e) => {
    if (
      e &&
      ((e.shiftKey && e.keyCode === 9) || e.keyCode === 9 || e.keyCode === 13)
    ) {
      handleChange(e);
    }
  };

  return (
    <div
      className={clsx(
        'alf-panel',
        className,
        expanded && 'expanded',
        divFocus && !claim && 'onFocus',
      )}
    >
      <div
        id={id}
        role="button"
        tabIndex="0"
        className="summary"
        onFocus={() => setDivFocus(true)}
        onBlur={() => setDivFocus(false)}
        onClick={handleChange}
        onKeyUp={handleKeyUp}
        style={headerStyle}
        aria-controls={children.props.id}
        aria-expanded={!!expanded}
      >
        <div className="content">{header}</div>
        {claim ? (
          <div className="expand-icon">
            <Button
              id="expansion-button"
              ariaLabel="Toggle group"
              style={{ margin: 'auto' }}
              htmlType="button"
              className="alf-panel-button"
              onClick={handleChange}
              onFocus={() => setButtonFocus(true)}
              onBlur={() => setButtonFocus(false)}
            >
              View Details
              <Icon
                alt="Toggle group"
                name={expanded ? 'chevronUp' : 'chevronDown'}
                fontSize={14}
                color={buttonFocus ? '#fff' : '#6648dc'}
                onClick={handleChange}
                className="alf-panel-icon"
                style={{ marginLeft: 5, marginTop: 0 }}
              />
            </Button>
          </div>
        ) : (
          <Icon
            alt="Toggle group"
            name={expanded ? 'chevronUp' : 'chevronDown'}
            fontSize={14}
            color="#483499"
            onClick={handleChange}
          />
        )}
      </div>
      <Transition
        appear
        in={expanded}
        timeout={duration}
        onEnter={handleEnter}
        onEntering={handleEntering}
        onExit={handleExit}
        onExiting={handleExiting}
      >
        <div style={{ ...defaultStyles, ...styles }}>
          <div className="details" ref={divRef}>
            {expanded && children}
          </div>
        </div>
      </Transition>
    </div>
  );
};

ExpansionPanel.propTypes = {
  className: PropTypes.string,
  expanded: PropTypes.bool,
  onChange: PropTypes.func,
  header: PropTypes.any,
  children: PropTypes.any,
  headerStyle: PropTypes.object,
  claim: PropTypes.bool,
};

ExpansionPanel.defaultProps = {
  claim: false,
};
export default ExpansionPanel;
