import React from 'react';
import PropTypes from 'prop-types';
import { formats } from 'utils';

import { Typography, Col, Tooltip } from '../alf-design';
import './ClaimInformation.css';

const ClaimInformation = ({ claim }) => (
  <div className="claim-information">
    <Col xs={24} md={6} xl={3} xxl={3} className="row">
      <Typography type="caption1" className="title">
        Claim number
      </Typography>
      <Typography type="caption1" className="value">
        {claim?.claimNumber}
      </Typography>
    </Col>
    <Col xs={24} md={6} xl={3} xxl={3} className="row">
      <Typography type="caption1" className="title">
        Claim status
      </Typography>
      <Typography type="caption1" className="value">
        {claim?.claimStatus}
      </Typography>
    </Col>
    <Col xs={24} md={6} xl={3} xxl={3} className="row">
      <Typography type="caption1" className="title">
        Date of loss
      </Typography>
      <Typography type="caption1" className="value">
        {formats.dateFormat(claim?.loss?.date)}
      </Typography>
    </Col>
    <Col xs={24} md={6} xl={3} xxl={3} className="row">
      <Typography type="caption1" className="title">
        Date claim filed
      </Typography>
      <Typography type="caption1" className="value">
        {formats.dateFormat(claim?.claimFiledDate)}
      </Typography>
    </Col>
    <Col xs={24} md={12} xl={6} xxl={6} className="row">
      <Typography type="caption1" className="title">
        Claim department phone number
      </Typography>
      <Typography type="caption1" className="value">
        <a href={`tel:800-230-3758`} className="link" tabIndex="0">
          (800) 230-3758
        </a>
      </Typography>
    </Col>
    <Col xs={24} md={12} xl={6} xxl={6} className="row">
      <Typography type="caption1" className="title">
        Claim department email
        <Tooltip
          ariaLabel="Claim Department Email Tooltip"
          text="For EXISTING claims only. Not for reporting new claims. The email subject line MUST include the claim number."
          dataFor="claimEmail"
        />
      </Typography>
      <a
        href={`mailto:claims@slideinsurance.com?subject=Claim #${claim?.claimNumber}`}
        className="link"
      >
        claims@slideinsurance.com
      </a>
    </Col>
  </div>
);

ClaimInformation.propTypes = {
  claim: PropTypes.object,
};

export default ClaimInformation;
